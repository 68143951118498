.landing-page .header {
    padding: 10px 65px !important;
}

.chat-ai .header .logo img {
    height: 60px;
    margin-left: 15px;
}

.login-page .login-details .login-content p,
.login-details ol {
    font-size: 15px;
}

.text-white {
    color: #FFFFFF
}

.card-img {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.errorlist {
    list-style: none;
    padding-inline-start: 0px;
    color: #bd2130;
}

.error {
    color: #bd2130;
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

@keyframes blink {
    50% {
        color: transparent
    }
}

.loader__dot {
    animation: 1s blink infinite
}

.loader__dot:nth-child(2) {
    animation-delay: 250ms
}

.loader__dot:nth-child(3) {
    animation-delay: 500ms
}

.skeleton-loader {
    opacity: 0.2;
    overflow: hidden;
    /* Clip the image to prevent gradient overflow */
    height: 20px;
    /* Adjust height as needed */
    width: 100%;
    /* Adjust width as needed */
    background-image: url("../../@core/assets/images/landing-bg.png");
    /* Set the background image */
    background-size: cover;
    /* Ensure the image covers the container */
    animation: shimmer 2s infinite ease-in-out;
    margin-bottom: 5px;
}

.skeleton-loader:first-child {
    animation: shimmer 1.25s infinite ease-in-out;
}

.skeleton-loader:last-child {
    animation: shimmer 2.75s infinite ease-in-out;
}

@keyframes shimmer {
    0% {
        background-position: -800px 0;
        /* Start with gradient far off-screen */
    }

    100% {
        background-position: 0 0;
        /* Animate to move gradient across the image */
    }
}

/*scroll bar css*/
/* Applies to the entire scrollbar */
::-webkit-scrollbar {
    width: 12px;
    /* Width of the vertical scrollbar */
    height: 12px;
    /* Height of the horizontal scrollbar */
}

/* Style for the track (part the thumb slides within) */
::-webkit-scrollbar-track {
    background-color: transparent;
    /* Makes the track transparent */
}

/* Style for the handle (draggable part) */
::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    /* Light grey color */
    border-radius: 10px;
    /* Rounded corners for the scrollbar thumb */
    border: 3px solid transparent;
    /* Transparent border for the thumb */
    background-clip: content-box;
    /* Ensures the background is only inside the border */
}

/* Optional: style for the scrollbar when hovered */
::-webkit-scrollbar-thumb:hover {
    background-color: #a8a8a8;
    /* Slightly darker shade of grey when hovered */
}

.login-form button:hover,
.login-form button:focus {
    background-color: #717bfd !important;
    border-color: #5661F6 !important;
}

.login-page .login-details {
    padding: 20px 20px 0px 40px;
}

.login-page .login-details .login-content {
    margin-top: 0px;
    max-height: 90%;
    line-height: 21.5px;
}

.login-page .login-details .login-content ol,
.login-page .login-details .login-content p {
    margin: 10px 0px;
}


/* Mic animation */
@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}

.pulse-animation {
    animation: pulse 1s infinite;
}

.chat-ai #content-wrapper .input-content .form-control {
    resize: none;
    padding: 12px;
}

.chat-ai #content-wrapper .highlighted-content .boxes .card .card-content {
    padding: 10px;
}

.chat-ai #content-wrapper .highlighted-content .landing-content {
    margin-bottom: 60px;
}

.chat-ai .header {
    margin-top: 0px;
    left: 0;
    width: 100%;
}

.landing-page .body-section .content .departments .hr-sales .icon {
    padding: 30px;
}

.chat-ai #content-wrapper .input-content .send {
    margin-top: 1px;
    margin-right: 2px;
    padding: 7px;
}

.chat-ai #content-wrapper .input-content .send img {
    width: 30px;
    height: 30px;
    min-height: 30px;
    min-width: 30px;
    max-width: 30px;
    max-height: 30px;
}

.dropzone {
    min-height: inherit !important;
    max-width: inherit !important;
}

.spinner {
    border: 12px solid #f3f3f3;
    border-top: 12px solid #5661F6;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    animation: spin 2s linear infinite;
    margin: 20% auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1031;
    backdrop-filter: blur(10px);
}

.text-right {
    text-align: right;
}

.pdf-sidebar-main-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
}

.right-pdf-icon-wrap {
    max-width: max-content;
    display: flex;
    align-items: center;
    border: 1px solid #C7C7C7;
    border-radius: var(--bs-border-radius-pill) !important;
    padding: 13px;
    cursor: pointer;
    margin-right: 15px;
    margin-bottom: 15px;
}

.right-pdf-icon-wrap:hover {
    background-color: #5661F6;
    color: #fff;
}

.right-pdf-icon-wrap img,
.right-pdf-icon-wrap svg {
    margin-right: 10px;
    width: 25px;
}

.right-pdf-icon-wrap svg {
    width: 27px;
    height: 27px;
    fill: #5661f6;
    /* Default color */
    transition: fill 0.3s ease;
}

.right-pdf-icon-wrap:hover svg {
    fill: #FFF;
    /* Hover color */
}

.document-link {
    color: cornflowerblue;
    cursor: pointer;
}

@-webkit-keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

.fadeInRightBig {
    -webkit-animation-name: fadeInRightBig;
    animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeOutRightBig {
    0% {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0);
    }
}

@keyframes fadeOutRightBig {
    0% {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0);
    }
}

.fadeOutRightBig {
    -webkit-animation-name: fadeOutRightBig;
    animation-name: fadeOutRightBig;
}

.animated {
    animation-duration: 0.8s;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.8s;
    -webkit-animation-fill-mode: both;
}

/* Modal styles */
.modal {
    display: none;
    position: fixed;
    z-index: 1032;
    width: 100%;
    height: 100%;
    overflow: auto;
    /* background-color: rgba(0, 0, 0, 0.4); */
}

.modal.show {
    right: 0;
    display: flex;
}

.modal-content {
    background-color: #fefefe;
    border: 1px solid #888;
    width: 50% !important;
    height: 100%;
    left: 50%;
}

.close {
    /* position: absolute; */
    top: -15px;
    left: 3px;
    color: #aaa;
    font-size: 39px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover {
    color: #927eec;
    text-decoration: none;
    cursor: pointer;
}

.btn-group .btn-check:checked+.btn,
.btn-group .btn.active,
.btn-group .btn.show,
.btn-group .btn:first-child:active,
.btn-group :not(.btn-check)+.btn:active {
    background-color: #5661F6 !important;
}

.dropdown button {
    padding: 4px 12px;
}

.dropdown-menu a:active {
    background-color: #5661F6;
}

.dropdown-menu.show {
    display: none;
}

.provided-ans {
    /* text-align: justify; */
    overflow: auto;
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .login-page .login-form {
        padding: 150px 100px;
    }
}

@media (min-width: 768px) and (max-width: 999px) {
    .login-page .login-form {
        padding: 150px 80px;
    }
}

@media only screen and (max-width: 991px) {
    .login-page .login-form {
        padding: 100px 60px;
    }

    .login-page .login-details .login-content {
        height: 800px;
    }

    .img-fluid {
        margin: 0 auto;
    }

    .chat-ai .input-content .mb-5 {
        margin-bottom: 3px !important;
    }

    .chat-ai #content-wrapper .user-content {
        padding-left: 10px;
        padding-right: 10px;
    }

    .modal-content {
        width: 90% !important;
        left: 0;
    }

    .chat-ai #content-wrapper .input-search {
        max-width: 100%;
    }

    .chat-ai #content-wrapper .btn {
        padding-left: 8px;
        padding-right: 8px;
    }

    .chat-ai #content-wrapper .user-content:last-child {
        padding-bottom: 0px;
    }

    .chat-ai #content-wrapper .input-content .form-control {
        padding-right: 5px;
        padding-bottom: 5px;
        margin: 0px;
    }

    textarea::placeholder {
        font-size: 15px;
    }

    .chat-ai #content-wrapper .input-content .send img {
        width: 25px;
        height: 25px;
        min-height: 25px;
        min-width: 25px;
        max-width: 25px;
        max-height: 25px;
    }

    .chat-ai #content-wrapper .input-content .form-control {
        height: 45px;
    }
}

@media only screen and (max-width: 389px) {
    .login-page .login-details .login-content {
        height: 550px;
        overflow: auto;
    }

    .login-page .login-form {
        padding: 50px 50px;
    }

    .login-page .login-details {
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media only screen and (max-width: 420px) {
    .chat-ai .header .logo img {
        margin-left: 5px;
        margin-right: 15px;
    }
}

@media only screen and (max-width: 575px) {
    .chat-ai .landing-boxes .landing-content {
        margin-bottom: 20px !important;
    }

    .chat-ai .landing-boxes .landing-content h2 {
        font-size: 22px !important;
    }

    .chat-ai #content-wrapper .highlighted-content .boxes .card .card-content {
        padding: 0px;
    }

    .chat-ai #content-wrapper .highlighted-content .boxes .card .card-content h3 {
        font-size: 18px !important;
        line-height: 25px;
    }

    .chat-ai .header .user-setting .dropdown .user-button-setting img {
        margin-right: 0px;
    }

    .btn-setting.logout,
    .dropdown span {
        display: none;
    }

    .dropdown button {
        padding: 4px 7px 4px 12px;
    }

    .dropdown-menu.show {
        display: flex;
    }
}

@media only screen and (max-width: 376px) {
    .login-page .login-details .login-content {
        height: 550px;
        overflow: auto;
    }

    .chat-ai #content-wrapper .btn {
        padding-left: 4px;
        padding-right: 4px;
    }

    .chat-ai #content-wrapper .input-content .form-control {
        padding-right: 5px;
        margin: 0px;
    }

    textarea::placeholder {
        letter-spacing: 0.3px;
        font-size: 14px;
    }

    .chat-ai .header .logo img {
        height: 45px;
        margin-right: 10px;
    }

    .chat-ai .landing-boxes {
        height: 100% !important;
        margin-bottom: 0px !important;
    }
}

@media only screen and (max-height: 601px) and (orientation: landscape) {
    .chat-ai .landing-boxes {
        height: 100% !important;
        margin-bottom: 0px !important;
    }

    .chat-ai .input-content .mb-5 {
        margin-bottom: 5px !important;
    }

    .chat-ai #content-wrapper .input-search {
        max-width: 100% !important;
    }
}
