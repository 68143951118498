* {
  margin: 0;
  padding: 0;
  font-family: "DM-Sans-Regular" !important;
  box-sizing: border-box;
}

body {
  font-family: "DM-Sans-Regular" !important;
  font-size: 16px;
  background-color: #F3F7FB;
  color: #000;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

.login-details ol {
  list-style: decimal;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

#user_dashboard, #user_dashboard_chat {
  .hide {
    opacity: 0;
    visibility: hidden;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #ddd;
    background-color: #f8f8f8 !important;
  }

  margin-bottom table th {
    background-color: #d6d6d6 !important;
  }

  table th,
  table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
}

#chat_interface {
  padding-bottom: 0;
  height: 87vh;
  overflow: auto;
  
  @media (max-width: 992px) {
    padding-bottom: 3rem;
  }
}


.MuiFormHelperText-contained {
  color: red !important;
  margin-left: 0 !important;
  font-size: 14px !important;
}

.suggest-question {
  cursor: pointer;
}


.table-cell-custom {
  max-height: 200px;
  word-wrap: break-word;
  max-width: 250px;
  width: max-content;
  overflow: auto;
}

.table-header-cell-custom {
  span {
    min-width: max-content;
  }
}

.chart-rendered {
  min-width: 100%;
}

.chatbot-actions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 2rem;
  margin: 10px;
}